import  { useCallback} from 'react';

import { DUTCH, FRENCH } from '../../constants/langs';
import EN from '../intl/en.json';
import FR from '../intl/fr.json';
import NL from '../intl/nl.json';

enum Langs {
  NL = 'nl',
  EN = 'en',
  FR = 'fr',
}

const useLangHandler = () => {
  const handleSetDefaultLanguage = useCallback(() => {
    const path = window.location.pathname.substring(1, 3)
    if (path === Langs.NL) {
      return Langs.NL
    }
    if (path === Langs.FR) {
      return Langs.FR
    }
    if (path === Langs.EN) {
      return Langs.EN
    }
  }, [])

  const handleLangCodeTables = (): { iso: string; messages: any } => {
    const path = window.location.pathname.substring(1, 3)
    if (path === Langs.NL) {
      return { iso: DUTCH, messages: NL }
    }
    if (path === Langs.FR) {
      return { iso: FRENCH, messages: FR }
    }
    if (path === Langs.EN) {
      return { iso: DUTCH, messages: EN }
    }
    return { iso: DUTCH, messages: NL }
  }

  const setDefaultLangIfNotPresent = () => {
    const lang = handleSetDefaultLanguage()
    if (!lang) {
      window.location.href = '/nl'
    }
  }

  return { handleSetDefaultLanguage, handleLangCodeTables, setDefaultLangIfNotPresent }
}

export default useLangHandler
