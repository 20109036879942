import { Box, Typography } from '@mui/material'
import Loading from 'components/common/loading/loading'
import useRouting from 'constants/routing'
import useCompanyInfo from 'hooks/useCompanyInfo'
import useComponents from 'hooks/useComponents'
import Cookies from 'js-cookie'
import UnderConstruction from 'pages/underConstruction/underConstruction'
import React, { Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import useLangHandler from './langHandler'

const RouterConnect: React.FC = () => {
  const { isSuccess: isSuccessWebComponents } = useComponents()
  const { isSuccess: isSuccessCompanyInfo } = useCompanyInfo()
  const routing = useRouting()
  const { setDefaultLangIfNotPresent } = useLangHandler()
  setDefaultLangIfNotPresent()

    return (
      <Suspense
        fallback={
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box mb={5}>
                <Typography variant='h1'>
                  <FormattedMessage id="content.loading.message" />
                </Typography>
              </Box>
              <Loading />
            </Box>
          </Box>
        }
      >
        <BrowserRouter>
          <Switch>
            <Route
              path="/admin"
              component={() => {
                window.location.href = 'https://cms.ipure.be/admin'
                return null
              }}
            />

            {isSuccessWebComponents && isSuccessCompanyInfo ? (
              routing.map((route) => (
                <Route key={route.key} path={route.router}>
                  {route.component}
                </Route>
              ))
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Box mb={5}>
                  <Typography variant='h4'>
                      <FormattedMessage id="content.loading.message" />
                    </Typography>
                  </Box>
                  <Loading />
                </Box>
              </Box>
            )}
          </Switch>
        </BrowserRouter>
      </Suspense>
    )
 
}
export default RouterConnect
