import { Box } from '@mui/system'
import React from 'react'
import { ReactComponent as Logo } from 'assets/ui-elements/logo.svg'

const Top = (): JSX.Element => {
  return (
    <Box width={200} margin="200px auto 0 auto">
      {/* <Logo /> */}
    </Box>
  )
}

export default Top
