export const queryKeys = {
    homeInfo: 'homeInfo',
    productsInfo: 'productsInfo',
    contactInfo: 'contactInfo',
    galleryInfo: 'galleryInfo',
    products: 'products',
    applications:'applications',
    services: 'services',
    mediaParent : 'mediaParent',
    websiteComponents: 'websiteComponents',
    companyInfo: 'companyInfo',
    websiteInfo: 'websiteInfo',
    bannerTexts: 'bannerTexts',
    jwtToken: 'jwtToken'
}