import { Box, Typography, useTheme } from '@mui/material'
import { queryKeys } from 'constants/queryKeys'
import { CompanyInfo } from 'hooks/useCompanyInfo'
import useIconsHandler from 'hooks/useIconsHandler'
import React from 'react'
import { useQueryClient } from 'react-query'
import { LinkStyled } from '../nav/nav.styled'
import useMediaQuery from '@mui/material/useMediaQuery'
const SocialBar = () => {
  const queryClient = useQueryClient()
  const { handleIcon } = useIconsHandler()
  const companyInfo = queryClient.getQueryData<CompanyInfo[]>([queryKeys.companyInfo])
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('xl'))
  return (<Box display="flex" justifyContent="center" >
    {companyInfo
      ?.filter((t) => t.acf.location.includes('footer'))
      .map((x) => {
        return (
          <LinkStyled target="_blank" rel="noopener" underline="hover" href={x.acf.link} key={x.id}>
            <Box display="flex" alignItems="center" justifyContent="center">
              {handleIcon(x.acf.icon_field_name)}
              {x.acf.show_text && (
                <Box >
                  <Typography color="#043A5C" fontWeight={500}>
                    {x.acf.title}
                  </Typography>
                </Box>
              )}
            </Box>
          </LinkStyled>
        )
      })}
  </Box>)
}

export default SocialBar