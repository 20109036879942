import { Box, Container } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { HamburgerVortexReverse } from 'react-animated-burgers'
import { Animated } from 'react-animated-css'
import Logo from '../nav/logo'
import Navigation from '../navigation'
const TopbarMobile = () => {
  const [isActive, setIsActive] = useState(false)
  const toggleButton = useCallback(() => setIsActive((prevState) => !prevState), [])
  return (
    <Container>
      <Box mt={2} display="flex" justifyContent="space-between">
        <Box zIndex="12" display="flex" justifyContent="space-between" width={'100vw'}>
          <Logo width="128" />
          <HamburgerVortexReverse buttonColor="#fff" barColor="#043A5C" {...{ isActive, toggleButton }} />
        </Box>
        <>
          <Animated
            animationIn="slideInRight"
            animationOut="slideOutRight"
            animateOnMount={false}
            isVisible={isActive}
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              padding: '2rem',
              zIndex: '11',
              backgroundColor: '#fff',
              height: '100vh',
              width: '100vw',
            }}
            animationOutDuration={250}
          >
            
            <Navigation mobile handleParentClick={() => setIsActive(!isActive)} />
         
          </Animated>
        </>
      </Box>
    </Container>
  )
}

export default TopbarMobile
