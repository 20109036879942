import { styled } from '@mui/system'
import { Grid } from '@mui/material'

export const AboutStyled = styled(Grid)({
  position: 'relative',
  // boxShadow: "0px 12px 15px -12px #000000",
  // top: '-2em',
  background: '#ffffff',
  zIndex: 5,
})
