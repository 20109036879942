import React from 'react'
import { IntlProvider } from 'react-intl'
import useLangHandler from 'services/router/langHandler'

const Intl: React.FC = ({ children }) => {
  const { handleLangCodeTables } = useLangHandler()
  const { iso, messages } = handleLangCodeTables()

  return (
    <IntlProvider locale={iso} messages={messages}>
      {children}
    </IntlProvider>
  )
}
export default Intl
