import { CssBaseline } from '@mui/material'

import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import ReactQueryProvider from 'services/reactQuery'
import Intl from './services/intl'
import RouterService from './services/router'
import theme from 'services/theme/theme'
import 'animate.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './main.scss'
import GoogleRecaptcha from 'services/google-recaptcha'
import { SnackbarProvider } from 'notistack'

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <SnackbarProvider>
      <GoogleRecaptcha>
        <Intl>
          <ReactQueryProvider>
            <RouterService />
          </ReactQueryProvider>
        </Intl>
      </GoogleRecaptcha>
    </SnackbarProvider>
  </ThemeProvider>
)

export default App
