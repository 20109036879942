
import { Box, Grid, Typography } from '@mui/material'
import { CompanyInfo } from 'hooks/useCompanyInfo'

import React, { useEffect, useState } from 'react'
import Logo from '../nav/logo'
import Navigation from '../navigation'

import useIconsHandler from 'hooks/useIconsHandler'
import { useQueryClient } from 'react-query'
import { queryKeys } from 'constants/queryKeys'
import { LinkStyled } from '../nav/nav.styled'
import { useLocation } from 'react-router-dom'
import Flag from 'react-world-flags'

const langListObject = [
    {
        langLink: '/nl',
        label: 'nl',
        fullName: 'Nederlands',
    },
    {
        langLink: '/fr',
        label: 'fr',
        fullName: 'Français',
    },
    {
        langLink: '/en',
        label: 'us',
        fullName: 'English',
    },
]

const LanguageSwitcher: React.FC = () => {
    const { pathname } = useLocation()

    const handleLangSwitch = (lang: string) => {
        const location = pathname.substring(3, pathname.length)
        window.location.href = lang + location
    }
    return (
        <> {langListObject.map((l) => {
            return (
                <LinkStyled key={l.label} underline="hover" onClick={() => handleLangSwitch(l.langLink)}>
                    <Typography color="#043A5C" fontWeight={500}>
                        <Box display="flex" alignItems="center">
                            <Box width="25px" mr={1}>
                                <Flag code={l.label} />
                            </Box>

                            {l.fullName}
                        </Box>
                    </Typography>
                </LinkStyled>
            )
        })}</>
    )
}

export default LanguageSwitcher