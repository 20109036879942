import { useQuery } from 'react-query'
import { queryKeys } from 'constants/queryKeys'
import { getCompanyInfo } from 'api/GetRequests'

export type AcfProps = {
  name: string
  link: string
  icon_field_name: string
  title?: string
  show_text?: boolean
  location: string[]
}
export type CompanyInfo = {
  acf: AcfProps
  id: number
}

const useCompanyInfo = () => {
  return useQuery<CompanyInfo[]>([queryKeys.companyInfo], getCompanyInfo)
}

export default useCompanyInfo
