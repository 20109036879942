import { Container, Box } from '@mui/material'
import React from 'react'
import FooterNav from 'components/common/nav/footerNav'
import Credits from './credits'
import Top from './top'
import SocialBar from 'components/common/socialBar'
const Footer: React.FC = () => {
  return (
    <>
      <Container>
        <Top />
      </Container>
      <Box>
        <Container>
          <FooterNav />
          <SocialBar/>
          <Credits />
        </Container>
      </Box>
    </>
  )
}

export default Footer
