import React from 'react'

import { Phone, WhatsApp,  LocationOn } from '@mui/icons-material'
import {ReactComponent as Messenger} from 'assets/ui-elements/messenger.svg';

const useIconsHandler = () => {
  const handleIcon = (icon: string): JSX.Element | null => {
    const sanitizedName = icon?.toLocaleLowerCase().trim()
    if (sanitizedName === 'phone') {
      return <Phone color="secondary" />
    }
    if (sanitizedName === 'whatsapp') {
      return <WhatsApp color="secondary" />
    }
    if (sanitizedName === 'location_pin') {
      return <LocationOn color="secondary" />
    }
    if (sanitizedName === 'facebook_messenger') {
      return <Messenger fill="#043A5C" />
    }

    return null
  }
  return { handleIcon }
}

export default useIconsHandler
