import { useQuery } from 'react-query'
import { queryKeys } from 'constants/queryKeys'
import { getServices } from 'api/GetRequests'
import { Item } from './types/ItemTypes'

const useServices = () => {
  return useQuery<Item[]>([queryKeys.services], getServices, {})
}

export default useServices
