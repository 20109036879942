import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import '@fontsource/raleway/100.css'
import '@fontsource/raleway/400.css'
import '@fontsource/raleway/500.css'
import '@fontsource/raleway/700.css'
import 'react-image-gallery/styles/scss/image-gallery.scss'
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
