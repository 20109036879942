import { Box, Typography } from '@mui/material'
import { routingProps } from 'constants/routing'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import MegaMenu from '../megamenu'
import { styled } from '@mui/system'
import { Animated } from 'react-animated-css'
import { Item } from 'hooks/types/ItemTypes'
import { FormattedMessage } from 'react-intl'

type SubMenuProps = {
  routeA: routingProps
  lang: string
  open: boolean
  candidate?: string
  mobile?: boolean
  handleClick: () => void
}

export const LinkStyled = styled(Box)((props) => ({
  ':hover': {
    cursor: 'pointer',
  },
}))


const SubMenu: React.FC<SubMenuProps> = ({ routeA, lang, open, candidate, mobile, handleClick }) => {
  const navItem = `title_${lang}`
  const { push } = useHistory()
  const handleSubClick = (route: Item) => {
    push('/' + lang + routeA.url + '/' + route.id)
    handleClick()
  }

  const handleVisibility = (x: routingProps): boolean => candidate === x.formatedId && x.hasChildren && open
  if (!mobile) {
    return (
      <Animated
        animationIn="fadeInUp"
        animationOut="fadeOutDown"
        animateOnMount={false}
        isVisible={handleVisibility(routeA)}
        animationInDuration={200}
        animationOutDuration={200}
        style={{
          border: '1px solid #ddd',
          borderRadius: '8px',
          position: 'absolute',
          marginTop: '18px',
          left: '-16px',
          top: '36px',
          padding: '16px',
          width: '456px',
          backgroundColor: '#fff',
          boxShadow: '0 0 28px -20px rgb(0 0 0 / 40%)',
          zIndex: 9500,
        }}
      >
        <MegaMenu label={routeA.formatedId}>
          {routeA.childLoaded &&
            routeA.childData?.filter(x=>x.acf.navigation_item).map((route: Item) => (
              <Box key={route.id} minWidth="50%" marginBottom="8px" paddingRight="8px">
                <Link to={'/' + lang + routeA.url + '/' + route.id}>
                  <Typography sx={{ textDecoration: 'underline', color: 'rgb(65, 132, 153)' }}>{route.acf[navItem]}</Typography>
                </Link>
              </Box>
            ))}
        </MegaMenu>
      </Animated>
    )
  } else {
    return (
      <Animated
        animationIn="fadeInRight"
        animationOut="fadeOutRight"
        animateOnMount={false}
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={handleVisibility(routeA)}
        style={{ zIndex: '999' }}
      >
        <Link to={'/' + lang + routeA.url + '/'}>
          <FormattedMessage id="menu.submenu_mobile.all" /> <FormattedMessage id={routeA.formatedId} />
        </Link>
        {routeA.childLoaded &&
          routeA.childData?.filter(x=>x.acf.navigation_item).map((route: Item) => (
            <LinkStyled key={route.id} minWidth="50%" marginBottom="8px" paddingRight="8px" onClick={() => handleSubClick(route)}>
              <Typography sx={{ textDecoration: 'underline', color: 'rgb(65, 132, 153)' }}>{route.acf[navItem]}</Typography>
            </LinkStyled>
          ))}
      </Animated>
    )
  }
}

export default SubMenu
