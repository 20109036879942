import { Box, styled } from '@mui/system'
import { List, Link } from '@mui/material'

export const ListNav = styled(List)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  padding: 0,
  width: 'unset',
  textTransform: 'capitalize',
  fontWeight: 'lighter',
  fontSmooth: 'antialiased',
})

export const DesktopStyled = styled(Box)((props) => ({
  // [props.theme.breakpoints.up('xs')]: {
  //   display: 'none',
  // },
  // [props.theme.breakpoints.up('sm')]: {
  //   display: 'none',
  // },
  // [props.theme.breakpoints.up('md')]: {
  //   display: 'flex',
  //   justifyContent: 'flex-end',
  // },
}))

export const MobileStyled = styled(Box)((props) => ({
  zIndex: 99,
  transition: 'all 1s',
  [props.theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
  [props.theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

export const StyledListMobileBox = styled(Box)((props) => ({
  [props.theme.breakpoints.up('xs')]: {
    width: '50vw',
    display: 'flex',
  },
  [props.theme.breakpoints.up('sm')]: {
    width: '50vw',
    display: 'flex',
  },
  [props.theme.breakpoints.up('md')]: {
    display: 'none',
    width: '35vw',
  },
  zIndex: -1,
  left: 0,
  top: 0,
  position: 'absolute',
  backgroundColor: '#fff',

  justifyContent: 'flex-start',
  flexDirection: 'column',
  height: '100vh',
  padding: '1rem',
  margin: '-0.5rem',
  alignItems: 'flex-start',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  marginTop: '4rem',
}))


export const LinkBoxStyled = styled(Box)((props) => ({
  transition: '.2s all',
  display: "flex",
  alignItems:"center",
  [props.theme.breakpoints.up('xs')]: {
    minWidth: '150px',
    backgroundColor: 'white',
  },
  [props.theme.breakpoints.up('sm')]: {
    minWidth: '150px',
    backgroundColor: 'white',
  },
  [props.theme.breakpoints.up('md')]: {
    minWidth: 'unset',
    backgroundColor: 'unset',
  },
  ':hover': {
    cursor: 'pointer',
    color:"#5EBDDB",
    TextDecoration: "underline",
  },
}))


export const LinkStyled = styled(Link)((props) => ({
  transition: '.2s all',
  [props.theme.breakpoints.up('xs')]: {
    textDecoration: 'none',
    color: '#000000',
    backgroundColor: 'white',
    padding: '.6rem 1rem .6rem .8rem',
  },
  [props.theme.breakpoints.up('sm')]: {
    textDecoration: 'none',
    color: '#000000',
    minWidth: '150px',
    backgroundColor: 'white',
    padding: '.6rem 1rem .6rem 1rem',
  },
  [props.theme.breakpoints.up('md')]: {
    textDecoration: 'none',
    color: '#000000',
    minWidth: 'unset',
    backgroundColor: 'unset',
    padding: '0rem 1rem 0rem 1rem',
  },
  ':hover': {
    cursor: 'pointer',
  },
}))

export const LinkStyledNav = styled(Link)((props) => ({
  transition: '.2s all',
  display:"flex",
  [props.theme.breakpoints.up('xs')]: {
    textDecoration: 'none',
    color: '#000000',
    minWidth: '150px',
    backgroundColor: 'white',
    padding: '0',
  },
  [props.theme.breakpoints.up('sm')]: {
    textDecoration: 'none',
    color: '#000000',
    minWidth: '150px',
    backgroundColor: 'white',
    padding: '0',
  },
  [props.theme.breakpoints.up('md')]: {
    textDecoration: 'none',
    color: '#000000',
    minWidth: 'unset',
    backgroundColor: 'unset',
    padding: '0',
  },
  ':hover': {
    cursor: 'pointer',
  },
}))


export const LinkSubStyled = styled(Link)((props) => ({
  transition: '1s all',
  [props.theme.breakpoints.up('xs')]: {
    textDecoration: 'none',
    color: '#000000',
    minWidth: '150px',
    backgroundColor: 'white',
  },
  [props.theme.breakpoints.up('sm')]: {
    textDecoration: 'none',
    color: '#000000',
    minWidth: '150px',
    backgroundColor: 'white',
  },
  [props.theme.breakpoints.up('md')]: {
    textDecoration: 'none',
    color: '#ffffff',
    minWidth: 'unset',
    backgroundColor: 'unset',
  },
  ':hover': {
    cursor: 'pointer',
  },
}))

export const SubMenu = styled(Box)({
  transition: 'all .2s',
})
