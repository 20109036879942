import { useQuery } from 'react-query'
import { queryKeys } from 'constants/queryKeys'
import { getWebsiteComponents } from 'api/GetRequests'

export type AcfProps = {
  title_nl: string
  title_fr: string
  title_en: string
  description_nl: string
  description_fr: string
  description_en: string
  gallery: any
  facebook: string
  instagram: string
  whatsapp: string
}
export type WebsiteComponents = {
  acf: {
    self: string
    description: string
    seo_keywords: string
  }
  id: number
}

const useComponents = () => {
  return useQuery<WebsiteComponents[]>([queryKeys.websiteComponents], getWebsiteComponents, {
    staleTime: 300000,
    cacheTime: 30000,
  })
}

export default useComponents
