import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import useLangHandler from 'services/router/langHandler'

const GoogleRecaptcha: React.FC = ({ children }) => {
  const { handleSetDefaultLanguage } = useLangHandler()
  const googleKey = process.env.REACT_APP_GOOGLE_RECAPTCHA ?? ''

  return (
    <GoogleReCaptchaProvider reCaptchaKey={googleKey} language={handleSetDefaultLanguage()}>
      {children}
    </GoogleReCaptchaProvider>
  )
}

export default GoogleRecaptcha
