import { Box, Typography } from '@mui/material'
import React from 'react'
import { FormattedMessage } from 'react-intl'

type Props = {
  label?: string
}

const MegaMenu: React.FC<Props> = ({ label, children }) => {
  return (
    <>
      <Box marginBottom="20px" zIndex={99}>
        <Typography fontSize="16px" fontWeight="700" margin-bottom="20px" width="100%">
          <FormattedMessage id={label} />
        </Typography>
      </Box>

      <Box display="flex" flexWrap="wrap">
        {children}
      </Box>
    </>
  )
}

export default MegaMenu
