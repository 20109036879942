import HomeIcon from '@mui/icons-material/Home'
import { Item } from 'hooks/types/ItemTypes'
import useApplications from 'hooks/useApplications'
import useProducts from 'hooks/useProducts'
import useServices from 'hooks/useServices'
import About from 'pages/about'

import Contact from 'pages/contact'
import Cookies from 'pages/cookies'
import Detail from 'pages/detail'
import Generic from 'pages/generic'
import Home from 'pages/home'

import TermsOfService from 'pages/termsOfService'
import React from 'react'
import { useParams } from 'react-router-dom'

import { de, en, fr, nl } from './langs'

export type routingProps = {
  url?: string
  key: string
  router: string
  formatedId: string
  footerItem: boolean
  navItem: boolean
  hasChildren: boolean
  component?: JSX.Element
  order: number
  nl?: string
  fr?: string
  en?: string
  de?: string
  icon?: JSX.Element
  childData?: Item[]
  childLoaded?: boolean
}

const useRouting = () => {
  const { data: servicesData, isSuccess: isSuccessServices } = useServices()
  const { data: productsData, isSuccess: isSuccessProducts } = useProducts()
  const { data: applicationsData, isSuccess: isSuccessApplications } = useApplications()

  return [
    {
      key: 'servicesDetail',
      router: '/:lang/services/:id',
      formatedId: 'header.nav.services',
      navItem: false,
      footerItem: false,
      hasChildren: false,
      component: <Detail data={servicesData} />,
      order: -1,
    },
    {
      key: 'productsDetail',
      router: '/:lang/products/:id',
      formatedId: 'header.nav.products',
      navItem: false,
      footerItem: false,
      hasChildren: false,
      component: <Detail data={productsData} />,
      order: -1,
    },
    {
      key: 'applicationsDetail',
      router: '/:lang/applications/:id',
      formatedId: 'header.nav.applications',
      navItem: false,
      footerItem: false,
      hasChildren: false,
      component: <Detail data={applicationsData} />,
      order: -1,
    },
    {
      key: 'about',
      router: '/:lang/about',
      formatedId: 'header.nav.about',
      navItem: true,
      footerItem: true,
      hasChildren: false,
      component: <About />,
      order: 2,
      nl: nl + /about/,
      fr: fr + /about/,
      en: en + /about/,
      de: de + /about/,
    },
    {
      key: 'products',
      url: '/products',
      router: '/:lang/products/',
      formatedId: 'header.nav.products',
      navItem: true,
      footerItem: true,
      component: <Generic key="products" type="products" data={productsData} />,
      order: 3,
      nl: nl + /products/,
      fr: fr + /products/,
      en: en + /products/,
      de: de + /products/,
      hasChildren: true,
      childData: productsData,
      childLoaded: isSuccessProducts,
    },
    {
      key: 'applications',
      url: '/applications',
      router: '/:lang/applications/',
      formatedId: 'header.nav.applications',
      navItem: true,
      footerItem: true,
      hasChildren: true,
      component: <Generic key="applications" type="applications" data={applicationsData} />,
      order: 2,
      nl: nl + /applications/,
      fr: fr + /applications/,
      en: en + /applications/,
      de: de + /applications/,
      childData: applicationsData,
      childLoaded: isSuccessApplications,
    },
    {
      key: 'services',
      url: '/services',
      router: '/:lang/services/',
      formatedId: 'header.nav.services',
      navItem: true,
      footerItem: true,
      hasChildren: true,
      component: <Generic key="services" type="services" data={servicesData} />,
      order: 2,
      nl: nl + /services/,
      fr: fr + /services/,
      en: en + /services/,
      de: de + /services/,
      childData: servicesData,
      childLoaded: isSuccessServices,
    },
    {
      key: 'contact',
      url: '/contact',
      router: '/:lang/contact',
      formatedId: 'header.nav.contact',
      navItem: true,
      footerItem: true,
      hasChildren: false,
      component: <Contact />,
      order: 5,
      nl: nl + /contact/,
      fr: fr + /contact/,
      en: en + /contact/,
      de: de + /contact/,
    },
    {
      key: 'termsOfService',
      url: '/terms-of-service',
      router: '/:lang/terms-of-service',
      formatedId: '',
      navItem: false,
      footerItem: true,
      hasChildren: false,
      component: <TermsOfService />,
      order: -2,
      nl: nl + /terms-of-service/,
      fr: fr + /terms-of-service/,
      en: en + /terms-of-service/,
      de: de + /terms-of-service/,
    },
    {
      key: 'cookies',
      url: '/cookies',
      router: '/:lang/cookies',
      formatedId: '',
      navItem: false,
      footerItem: true,
      hasChildren: false,
      component: <Cookies />,
      order: -3,
      nl: nl + /cookies/,
      fr: fr + /cookies/,
      en: en + /cookies/,
      de: de + /cookies/,
    },
    {
      key: 'home',
      url: '/',
      router: '/:lang/',
      formatedId: 'header.nav.home',
      navItem: true,
      footerItem: true,
      hasChildren: false,
      component: <Home />,
      order: 1,
      nl: nl + '/',
      fr: fr + '/',
      en: en + '/',
      de: de + '/',
      icon: <HomeIcon />,
    },
  ]
}

export default useRouting
