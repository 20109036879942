import React from 'react'
import { useIntl } from 'react-intl'
import Head from 'components/common/head'
import Footer from 'components/layout/footer'
import useComponents from 'hooks/useComponents'
import AboutComponent from '../../components/common/about/about'
import Header from '../../components/layout/header/headerNoVideo'
import { useParams } from 'react-router-dom'

const About: React.FC = () => {
  const { data: components } = useComponents()
  const data = components?.find((x) => x.acf.self === 'about')
  const { lang } = useParams<{ lang: string }>()
  const renderDescription = `description_${lang}`
  const renderTitle = `title_${lang}`

  return (
    <>
      <Head title={`${data?.acf[renderTitle]}`} description={`${data?.acf[renderDescription]}`} />
      <Header />
      <AboutComponent />
      <Footer />
    </>
  )
}

export default About
