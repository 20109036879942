import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import darkScrollbar from '@mui/material/darkScrollbar';

const theme = createTheme({
  components: {
    MuiListItem:{
      styleOverrides:{
        root:{
          width:"unset"
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          ...darkScrollbar(),
          backgroundColor: "white",
          overflowX:"hidden"
        }
      }
    }
  },
  typography: {
    h1:{
      fontSize:48,
      color:'white'
    },
    h2:{
      textTransform:"uppercase",
      fontSize:45,
      fontWeight:800,
      color:'#043A5C'
    },
    h3:{
      fontSize:20,
      fontWeight:800,
      color:"black"
    },
    h4:{
      fontSize:18,
      fontWeight:700
    },
    h5:{
      fontSize:12,
      fontWeight:500
    },
   body1:{
    color: "#333"
   },
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',].join(','),
  },
  palette: {
    primary: {
      main: '#5EBDDB',
    },
    secondary: {
      main: '#043A5C',
    },
    
    error: {
      main: red.A400,
    },
  },
  
});

export default theme;