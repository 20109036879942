/* eslint-disable array-callback-return */
import { Grid, Link, Typography, Box, useTheme } from '@mui/material';
import React from 'react';

import useRouting from 'constants/routing';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'



const FooterNav: React.FC = (): JSX.Element => {
  const routing = useRouting()
  const filter = [...routing.sort((a, b) => a.order - b.order)].filter((x) => x.navItem === true)
  const intl = useIntl();
  const { lang } = useParams<{ lang: string }>()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('xl'))

  return (
    <Grid style={{zIndex:1600}} container display="flex" justifyContent={{ xs: 'center', md: 'center' }} py={5} my={5} textAlign="center">
      <Grid display="flex" flexDirection={matches ? "row": "column"}>
        {filter.map((route) => {
          const condition = route.footerItem && route.url !== '/'
          return condition && <Box mr={2}>
            <Link href={route[lang]} sx={{textDecoration:"none"}}>
              <Typography fontWeight={700} color="#043A5C">
                {intl.formatMessage({ id: route.formatedId }).toUpperCase()}
              </Typography>
            </Link>
          </Box>
        })}
      </Grid>

    </Grid>
  )
}

export default FooterNav
