import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Parallax } from 'react-parallax'
import useComponents from 'hooks/useComponents'
import about from 'assets/ui-elements/about.jpg'
import { AboutStyled } from './about.styled'
import ScrollAnimation from 'react-animate-on-scroll'
import { Interweave } from 'interweave'
import { useParams } from 'react-router-dom'
import theme from 'services/theme/theme'
const AboutComponent: React.FC = () => {
  const { data: components, isSuccess } = useComponents()
  const aboutItem = components?.find((x) => x.acf.self === 'about')
  const { lang } = useParams<{ lang: string }>()
  const renderDescription = `description_${lang}`
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  return isSuccess ? (
    <Box mt={6}>
      <Container>
      <ScrollAnimation animateOnce={false} animateIn="fadeIn" duration={0.2}>
        <AboutStyled container direction="row">
          <Grid item md={12} sm={12} justifyContent="center" alignItems="center" alignSelf="center" mt={10}>
            <Typography variant="h2" textAlign="left" gutterBottom>
              <FormattedMessage id="content.titles.about" />
            </Typography>
            {aboutItem && (
              <Typography textAlign="left" variant="body1">
                <Interweave content={`${aboutItem?.acf?.[renderDescription]}`} />
              </Typography>
            )}
          </Grid>
        </AboutStyled>
      </ScrollAnimation>
    </Container>
    </Box>
    
  ) : null
}

export default AboutComponent
