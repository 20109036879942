import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Button, Typography } from '@mui/material'
import useRouting, { routingProps } from 'constants/routing'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import { LinkBoxStyled } from '../nav/nav.styled'
import SubMenu from './submenu'

type Props = {
  mobile?: boolean
  handleParentClick?: () => void
}

const Navigation: React.FC<Props> = ({ mobile, handleParentClick }) => {
  const routing = useRouting()
  const filter = [...routing.sort((a, b) => a.order - b.order)].filter((x) => x.navItem === true)
  const [open, setOpen] = React.useState(true)
  const { lang } = useParams<{ lang: string }>()
  const [candidate, setCandidate] = useState<string>()

  const handleClick = (routeObject: routingProps) => {
    if (!mobile) {
      setOpen(false)
      window.location.href = routeObject[lang]
    } else {
      if (routeObject.hasChildren) {
        setOpen(true)
      } else {
        setOpen(false)
        window.location.href = routeObject[lang]
      }
    }
  }

  const decideArrows = (children: boolean, mobile: boolean | undefined) => {
    if (children) {
      if (mobile) {
        return <ChevronRightIcon fontSize="medium" />
      }
      return <ExpandMoreIcon />
    }
    return null
  }

  const handleClickAway = () => {
    if (!mobile) {
      setOpen(false)
    }
  }

  const handleMouseOver = (x: routingProps) => {
    setCandidate(x.formatedId)
    setOpen(true)
  }

  const handleSubMenuClick = () => {
    handleParentClick && handleParentClick()

    setOpen(false)
  }

  return (
    <Box
      display="flex"
      flexDirection={mobile ? 'column' : 'unset'}
      justifyContent={mobile ? 'center' : 'unset'}
      ml={!mobile ? 5 : 0}
      mt={!mobile ? 0 : 15}
    >
      {filter.map((x) => {
        if (x.key === 'contact' && !mobile) {
          return (
            <Box mr={1}  onMouseOver={() => handleMouseOver(x)} display="flex" alignItems="center" position="relative">
              <Typography mx={1} color="white" key={x.key} display="flex" justifyContent="center">
                <Button onClick={() => handleClick(x)} style={{ color: 'white' }} variant="contained">
                  <FormattedMessage id={x.formatedId} />
                </Button>
              </Typography>
            </Box>
          )
        }
        if (x.hasChildren && x.childData && x.childData.length > 0 && x.childData.some(x=>x.acf.navigation_item)) {
          return (
            <Box key={x.key} display="flex" alignItems="center" position="relative">
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box marginLeft={mobile ? '0' : 1} display="flex" alignItems="center" height="35px">
                  <LinkBoxStyled
                    // mx={1}
                    fontSize={mobile ? 'xx-large' : 'unset'}
                    onMouseOver={() => handleMouseOver(x)}
                    onClick={() => handleClick(x)}
                    sx={{ height: '35px' }}
                  >
                    {/* {!mobile && x.icon && <Box mr={1}>{x.icon && x.icon}</Box>} */}

                    <Box
                      display="flex"
                      justifyContent={mobile ? 'space-between' : 'unset'}
                      height="35px"
                      minWidth={mobile ? '130px' : 0}
                      alignItems="center"
                    >
                      <Typography variant="body1">
                        <FormattedMessage id={x.formatedId} />
                      </Typography>

                      {decideArrows(x.hasChildren, mobile)}
                    </Box>
                  </LinkBoxStyled>

                  {x.childLoaded && x.childData ? (
                    <SubMenu
                      routeA={x}
                      lang={lang}
                      open={open}
                      candidate={candidate}
                      mobile={mobile}
                      handleClick={() => handleSubMenuClick()}
                    />
                  ) : null}
                </Box>
              </ClickAwayListener>
            </Box>
          )
        } else if (!x.hasChildren) {
          return (
            <LinkBoxStyled
              mr={1}
              fontSize={mobile ? 'xx-large' : 'unset'}
              onMouseOver={() => handleMouseOver(x)}
              onClick={() => handleClick(x)}
              sx={{ height: '35px' }}
              display="flex" alignItems="center" position="relative"
            >
              {/* {!mobile && x.icon && <Box mr={1}>{x.icon && x.icon}</Box>} */}

              <Box
                display="flex"
                justifyContent={mobile ? 'space-between' : 'unset'}
                height="35px"
                minWidth={mobile ? '130px' : 0}
                alignItems="center"
              >
                <Typography variant="body1">
                  <FormattedMessage id={x.formatedId} />
                </Typography>

                {decideArrows(x.hasChildren, mobile)}
              </Box>
            </LinkBoxStyled>
          )
        }
      })}
    </Box>
  )
}

export default Navigation
