import { Box, Grid, Typography } from '@mui/material'
import { CompanyInfo } from 'hooks/useCompanyInfo'

import React, { useEffect, useState } from 'react'
import Logo from '../nav/logo'
import Navigation from '../navigation'

import useIconsHandler from 'hooks/useIconsHandler'
import { useQueryClient } from 'react-query'
import { queryKeys } from 'constants/queryKeys'
import { LinkStyled } from '../nav/nav.styled'

import { TopbarStyled } from './topbar.styled'
import LanguageSwitcher from '../languageSwitcher'


const Topbar: React.FC = () => {
  const queryClient = useQueryClient()
  const { handleIcon } = useIconsHandler()
  const companyInfo = queryClient.getQueryData<CompanyInfo[]>([queryKeys.companyInfo])



  const [offset, setOffset] = useState<number>(0)
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset)
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [offset])

  return (
    <TopbarStyled>
      <Grid container mt={2} justifyContent="flex-end">
        <LanguageSwitcher/>
      </Grid>
      <Grid container mt={2} justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <Logo width="128" />
          <Navigation />
        </Grid>
        <Grid item display="flex" alignItems="center">
          {companyInfo
            ?.filter((t) => t.acf.location.includes('topbar'))
            .map((x) => {
              return (
                <LinkStyled target="_blank" rel="noopener" underline="hover" href={x.acf.link} key={x.id}>
                  <Box display="flex" alignItems="center" ml={1}>
                    {handleIcon(x.acf.icon_field_name)}
                    {x.acf.show_text && (
                      <Box ml={1}>
                        <Typography color="#043A5C" fontWeight={500}>
                          {x.acf.title}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </LinkStyled>
              )
            })}
        </Grid>
      </Grid>
    </TopbarStyled>
  )
}

export default Topbar
