import axios from "./axios";

export const getProducts = () => axios.get(`${process.env.REACT_APP_MAIN_API}${process.env.REACT_APP_JSON_EXTENSION}products?per_page=100`).then((res)=> res.data)
export const getProduct = (id:string | undefined) => axios.get(`${process.env.REACT_APP_MAIN_API}${process.env.REACT_APP_JSON_EXTENSION}products/${id}?&acf_format=standard`).then((res)=> res.data)
export const getMedia = () => axios.get(`${process.env.REACT_APP_MAIN_API}${process.env.REACT_APP_JSON_EXTENSION}media?per_page=100`).then((res)=> res.data)
export const getWebsiteComponents = () => axios.get(`${process.env.REACT_APP_MAIN_API}${process.env.REACT_APP_JSON_EXTENSION}components?per_page=100`).then((res)=> res.data)
export const getCompanyInfo = () => axios.get(`${process.env.REACT_APP_MAIN_API}${process.env.REACT_APP_JSON_EXTENSION}company_info?per_page=100`).then((res)=> res.data)
export const getWebsiteInfo = () => axios.get(`${process.env.REACT_APP_MAIN_API}${process.env.REACT_APP_JSON_EXTENSION}generic_website_info?per_page=100`).then((res)=> res.data)
export const getServices = () => axios.get(`${process.env.REACT_APP_MAIN_API}${process.env.REACT_APP_JSON_EXTENSION}services?per_page=100`).then((res)=> res.data)
export const getApplications = () => axios.get(`${process.env.REACT_APP_MAIN_API}${process.env.REACT_APP_JSON_EXTENSION}applications?per_page=100`).then((res)=> res.data)
export const getBanners = () => axios.get(`${process.env.REACT_APP_MAIN_API}${process.env.REACT_APP_JSON_EXTENSION}banners?per_page=100`).then((res)=> res.data)