import React from 'react'
import Topbar from 'components/common/topbar'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import TopbarMobile from 'components/common/topbar/topbarMobile'
const Header: React.FC = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  return matches ? (
    <Topbar />
  ) : (
    <>
      <TopbarMobile />
    </>
  )
}

export default Header
