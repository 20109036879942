import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { styled } from '@mui/system'
import { ReactComponent as LogoSvg } from '../../../assets/ui-elements/logo.svg'
import useLangHandler from 'services/router/langHandler'

const LinkCustom = styled(Link)((props) => ({
  textDecoration: 'none !important',
  textTransform: 'uppercase',
  [props.theme.breakpoints.up('xs')]: {
    color: '#043A5C',
    minWidth: '200px',
  },
  [props.theme.breakpoints.up('sm')]: {
    color: '#043A5C',
    minWidth: '200px',
  },
  [props.theme.breakpoints.up('md')]: {
    color: '#ffffff',
    minWidth: 'unset',
  },
}))
type Props = {
  width: string
}

const Logo: React.FC<Props> = ({ width }) => {
  const { handleSetDefaultLanguage } = useLangHandler()

  return (
    <LinkCustom to={`/${handleSetDefaultLanguage()}`}>
      <LogoSvg width={width} />
    </LinkCustom>
  )
}

export default Logo
