import { Link, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { ReactComponent as MethodsLogoSvg } from '../../../assets/ui-elements/methods_logo.svg'
const Credits: React.FC = () => {
  return (
    <Box display="flex" justifyContent="center" py={2} alignItems="center">
      <Typography fontWeight={100} color="black" mr={2}>
        Design and Development by
      </Typography>        <Link href="https://www.methods.digital">
          <MethodsLogoSvg width={55} height={60} />
        </Link>
    </Box>
  )
}
export default Credits
