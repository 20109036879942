import React from 'react'

import { Helmet } from 'react-helmet'
import sanitizeHtml from 'sanitize-html'

type Props = {
  url?: string
  title?: string
  description?: string
  slogan?: string
  seo?: string
}

const Head: React.FC<Props> = ({ url = '', title = '', description = '', slogan = '', seo = '' }) => {
  const descriptionSanitized = sanitizeHtml(description, {
    allowedTags: [],
    allowedAttributes: {},
  })

  return (
    <Helmet>
      <title>
        {title} {title && descriptionSanitized.length > 0 ? ' - ' : ""} {descriptionSanitized} {slogan} {slogan && ' - '} {url}
      </title>
      c
      <meta charSet="UTF-8" />
      <meta name="description" content={descriptionSanitized} />
      <meta name="keywords" content={seo.length === 0 ? descriptionSanitized.replaceAll(' ', ',').replaceAll('.', '') : seo} />
      <meta name="author" content={title} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
  )
}

export default Head
