
import axios, { AxiosRequestConfig } from "axios";


const baseUrl = process.env.REACT_APP_MAIN_API


const axiosApi = axios.create({
  headers:{
    'Content-Type' : 'application/json'
  },
  baseURL: baseUrl,

})



export default axiosApi;


